import { country } from '../../helpers/settings';
import { updateCartStore } from '../../helpers/store';
import { marketSettings, updateCachedCart } from '../../init';
import type {
    CartAgentType,
    CartErrorItem,
    DisplayUnit,
    Measurement,
} from '../../types';
import {
    type CSCartResponse,
    addItems,
    addPlannerCodes,
    fetchCart,
    removeItems,
    removePlans,
    updateItems,
    updatePlanners,
} from './caas/operations';
import type { CartBackendError } from './customError';

export type CartOperationResult = {
    cart: CartAgentType;
    addedItems?: { itemNo: string; quantity: number }[];
    error?: CartBackendError;
};

export const exclVatCountries = ['ca', 'us'];
const mapResponse = (
    cartResponse: CSCartResponse,
    items: Array<{ itemNo: string; quantity: number }>,
    error: CartBackendError | undefined
) => {
    const vatAccessor = exclVatCountries.includes(country)
        ? 'exclTax'
        : 'inclTax';
    const defaultGroup = cartResponse?.groups?.find(
        group => group.name === 'DEFAULT'
    );
    const quantity =
        (defaultGroup?.items?.reduce(
            (acc, current) => (current.quantity ?? 0) + acc,
            0
        ) ?? 0) +
        (defaultGroup?.plans?.reduce(
            (acc, current) => (current.quantity ?? 0) + acc,
            0
        ) ?? 0);
    const cart: CartAgentType = {
        cart_id: cartResponse?.cartId,
        quantity,
        items: defaultGroup?.items
            ?.map(item => {
                const hasPreviousPrice =
                    item.price?.unitPrice?.current?.[vatAccessor] !==
                    item.price?.unitPrice?.base?.[vatAccessor];
                return {
                    itemNo: item.itemNo ?? '',
                    quantity: item.quantity ?? 0,
                    itemType: item.itemType ?? null,
                    maxQuantity: item.maxQuantity ?? 999,
                    product: {
                        name: item?.info?.globalName ?? '',
                        images:
                            item?.info?.mainProductImages?.map(image => ({
                                url: image.url ?? '',
                                quality: image.quality ?? '',
                                width: image.width ?? '',
                            })) ?? [],
                        unitCode: item?.info?.unitCode,
                        category: item?.info?.category ?? '',
                        description: item?.info?.description ?? '',
                        displayUnit: item?.info?.displayUnit as DisplayUnit,
                        measurements: item?.info
                            ?.measurements as Array<Measurement>,
                        formattedItemNo: item.info?.formattedItemNo ?? '',
                    },
                    displayPrice:
                        item.price?.subtotal?.current?.[vatAccessor] ?? 0,
                    previousPrice: hasPreviousPrice
                        ? item.price?.unitPrice?.base?.[vatAccessor] ?? 0
                        : 0,
                    isFamilyItem: item.price?.unitPrice?.isFamilyItem ?? false,
                    pricePerItem:
                        item.price?.unitPrice?.current?.[vatAccessor] ?? 0,
                    pricePerItemGA:
                        item.price?.unitPrice?.current?.[
                            marketSettings.analytics.price.itemPriceType
                        ] ?? 0,
                    priceValidTo:
                        item.price?.unitPrice?.current?.validToDate ?? '',
                    lowestPreviousPrice:
                        item.price?.unitPrice?.lowestPreviousPrice?.[
                            vatAccessor
                        ] ?? 0,
                    priceOfferType: item.price?.unitPrice?.priceType ?? '',
                };
            })
            .reverse(),
        planners:
            defaultGroup?.plans
                ?.map(plan => {
                    return {
                        name: plan?.name ?? '',
                        code: plan?.code,
                        quantity: plan?.quantity,
                        displayPrice:
                            plan.price?.subtotal?.current?.[vatAccessor] ?? 0,
                        displayImage: plan.images?.[0]?.url ?? '',
                        pricePerItemGA:
                            plan.price?.unitPrice?.[
                                marketSettings.analytics.price.itemPriceType
                            ] ?? 0,
                        items:
                            plan.items?.map(item => ({
                                product: {
                                    name: item.info?.name ?? '',
                                },
                                itemNo: item.itemNo ?? '',
                                quantity: item.quantity ?? 0,
                                pricePerItemGA:
                                    item.price?.unitPrice?.current?.[
                                        marketSettings.analytics.price
                                            .itemPriceType
                                    ] ?? 0,
                            })) ?? [],
                    };
                })
                .reverse() ?? [],
        regularTotalPrice: {
            inclTax: cartResponse?.price?.totalPrice?.current?.inclTax ?? 0,
            exclTax: cartResponse?.price?.totalPrice?.current?.exclTax ?? 0,
        },
        packageWeight: '0 kg',
    };
    return {
        cart,
        addedItems: items,
        error,
    };
};

export const addItemsToCart = async (
    items: Array<{ itemNo: string; quantity: number }>,
    addPartial = false
): Promise<CartOperationResult> => {
    const res = await addItems(items);
    if (res?.error?.items && addPartial === true) {
        const invalidItems = res.error.items as Array<CartErrorItem>;
        const invalidItemNos = invalidItems.map(item => item.itemNo);
        const itemsToPartlyAdd = items.filter(
            item => !invalidItemNos.includes(item.itemNo)
        );
        if (itemsToPartlyAdd.length > 0) {
            const partialRes = await addItems(itemsToPartlyAdd);
            const partialResponse = mapResponse(
                partialRes.cart,
                itemsToPartlyAdd,
                partialRes.error
            );
            updateCachedCart(partialResponse);
            return partialResponse;
        }
    }
    const response = mapResponse(res.cart, items, res.error);
    if (!response.error) {
        updateCachedCart(response);
    }
    return response;
};

export const removeItemsFromCart = async (
    items: Array<string>
): Promise<CartOperationResult> => {
    const res = await removeItems(items);
    const response = mapResponse(res.cart, [], res.error);
    updateCartStore(response.cart);
    if (!response.error) {
        updateCachedCart(response);
    }
    return response;
};

export const removePlansFromCart = async (
    codes: Array<string>
): Promise<CartOperationResult> => {
    const res = await removePlans(codes);
    const response = mapResponse(res.cart, [], res.error);
    if (!response.error) {
        updateCachedCart(response);
    }
    return response;
};

export const updateItemsQuantities = async (
    items: Array<{ itemNo: string; quantity: number }>
): Promise<CartOperationResult> => {
    const res = await updateItems(items);
    const response = mapResponse(res.cart, [], res.error);
    if (!response.error) {
        updateCachedCart(response);
    }
    return response;
};

export const addPlannerCodesToCart = (
    codes: Array<string>
): Promise<CartOperationResult> => {
    if (!codes) {
        return Promise.reject('Invalid planners payload');
    }
    const plans = codes.map(code => {
        return {
            code,
            quantity: 1,
        };
    });
    return addPlannerCodes(plans).then(res => {
        const response = mapResponse(res.cart, [], res.error);
        if (!response.error) {
            updateCachedCart(response);
        }

        return response;
    });
};

export const updatePlannerQuantities = async (
    plans: Array<{ code: string; quantity: number }>
): Promise<CartOperationResult> => {
    const res = await updatePlanners(plans);
    const response = mapResponse(res.cart, [], res.error);
    if (!response.error) {
        updateCachedCart(response);
    }
    return response;
};

export const getCart = async (): Promise<CartOperationResult> => {
    const res = await fetchCart();
    const response = mapResponse(res.cart, [], res.error);
    // Update the store every time we fetch the cart
    updateCartStore(response.cart);
    if (!response.error) {
        updateCachedCart(response);
    }

    return response;
};
