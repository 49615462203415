import { type CartOperationResult, getCart } from './apis/cart/index.ts';
import { getToken, getTokenDetails } from './helpers/auth.ts';
import fetchCartPhraseIfNeeded from './helpers/fetchCartPhraseIfNeeded.ts';
import getMarketSettings from './helpers/getMarketSettings.ts';
import { getCookie, updateHeaderBadge } from './helpers/headerBadge.ts';
import { initOptimizely, optimizely } from './helpers/optimizely.ts';
import {
    COUNTRIES_WITH_BUSINESS_CART_URL,
    businessCustomerCartUrl,
    country,
    language,
    shoppingCartUrl,
} from './helpers/settings.ts';
import {
    clearCartStore,
    fetchValidCartData,
    fetchValidCartItemsData,
    subscribeCart,
} from './helpers/store';
import {
    ADD_TO_CART,
    AGENT_LOADED,
    REMOVE_FROM_CART,
    UPDATE_CART,
    UPDATE_CART_COUNT,
    UPDATE_CART_ITEM_QUANTITY,
    UPDATE_DATA,
    publish,
} from './pubsub';
import type {
    CartData,
    CartEvent,
    CartPayload,
    MinimalCartItem,
    RemoveFromCartEvent,
    RemoveFromCartPayload,
    SettingsDClass,
    UpdateCartCountEvent,
    UpdateCartCountPayload,
    UpdateDataEvent,
    UpdateDataPayload,
    UpdateQuantityEvent,
    UpdateQuantityPayload,
} from './types.ts';

export let cachedCartResult: CartOperationResult;
export let marketSettings: SettingsDClass;

const flexiCartExperiments = [
    'frakta-1141_flexi_cart_single_cta_global_v2',
    'frakta-1142_flexi_cart_single_cta_us_v2',
];
export const isFlexiCartExperimentOn = (wrapper = optimizely) => {
    return flexiCartExperiments.some(
        experiment => wrapper?.getVariation(experiment) === 'on'
    );
};

export const updateCachedCart = (cartResult: CartOperationResult) => {
    // Only update cached cart if in variation
    if (isFlexiCartExperimentOn()) {
        cachedCartResult = cartResult;
        updateHeaderBadge(cartResult.cart.quantity);
    }
};

export const handleCartRedirect = async (source: string) => {
    const flexiCartSources = ['header', 'internal'];
    let url = shoppingCartUrl;
    // If the flexi-cart experiment is active, the source is allowed,
    // and the current path is not '/shoppingcart', open the flexi-cart and exit.
    if (
        isFlexiCartExperimentOn() &&
        flexiCartSources.includes(source) &&
        !window.location.pathname.includes('/shoppingcart')
    ) {
        const module = await import('./handlers');
        module.handleOpenCartModal(cachedCartResult);
        return;
    }
    if (COUNTRIES_WITH_BUSINESS_CART_URL.includes(country)) {
        try {
            const token = await getToken();
            const userDetails = getTokenDetails(token);
            if (userDetails?.profileType === 'business') {
                url = businessCustomerCartUrl;
            }
        } catch (e) {
            reportError('Unable to redirect customer to business cart');
        }
    }

    window.location.href = url;
};

const _handleAddToCartSubscription = async (payload: CartPayload) => {
    const module = await import('./handlers');
    await module.handleAddToCartSubscription(payload);
};

const _handleUpdateDataSubscription = async (payload: UpdateDataPayload) => {
    const module = await import('./handlers');
    module.handleUpdateDataSubscription(payload);
};

const _handleUpdateCartCountSubscription = async (
    payload: UpdateCartCountPayload
) => {
    const module = await import('./handlers');
    await module.handleUpdateCartCount(payload);
};

const _handleRemoveFromCartSubscription = async (
    payload: RemoveFromCartPayload
) => {
    const module = await import('./handlers');
    await module.handleRemoveItem(payload);
};

const _handleUpdateQuantitySubsription = async (
    payload: UpdateQuantityPayload
) => {
    const module = await import('./handlers');
    await module.handleUpdateQuantity(payload);
};

function initCartAgent() {
    if (window.ikea.shoppingCart === undefined) {
        // Cache to avoid multiple requests simultaneuosly
        let getCartPromise: Promise<Array<MinimalCartItem>> | null = null;
        let getCartDataPromise: Promise<CartData> | null = null;
        window.ikea.shoppingCart = {
            getCart: async () => {
                try {
                    getCartPromise =
                        getCartPromise ?? fetchValidCartItemsData();
                    return await getCartPromise;
                } finally {
                    getCartPromise = null;
                }
            },
            getCartData: async () => {
                try {
                    getCartDataPromise =
                        getCartDataPromise ?? fetchValidCartData();
                    return await getCartDataPromise;
                } finally {
                    getCartDataPromise = null;
                }
            },
            open: ({ source }: { source: string }) => {
                handleCartRedirect(source);
            },
        };
    }
    window.ikea.shoppingCart.cartAgent = true;
    /**
     * Whenever the stored data is updated, we publish a pubsub event
     */
    subscribeCart(d => publish(UPDATE_CART, d));
    window.ikea.pubsub.subscribe<CartEvent>(
        ADD_TO_CART,
        _handleAddToCartSubscription
    );
    window.ikea.pubsub.subscribe<UpdateDataEvent>(
        UPDATE_DATA,
        _handleUpdateDataSubscription
    );
    window.ikea.pubsub.subscribe<UpdateCartCountEvent>(
        UPDATE_CART_COUNT,
        _handleUpdateCartCountSubscription
    );
    window.ikea.pubsub.subscribe<UpdateQuantityEvent>(
        UPDATE_CART_ITEM_QUANTITY,
        _handleUpdateQuantitySubsription
    );
    window.ikea.pubsub.subscribe<RemoveFromCartEvent>(
        REMOVE_FROM_CART,
        _handleRemoveFromCartSubscription
    );
}

const cartCount = getCookie(`IRMW_CART_COUNT_${country.toUpperCase()}`);
const isCartEmpty = cartCount === '0' || cartCount === '';
const isDev = process.env.NODE_ENV === 'development';

export async function init() {
    if (window.ikea) {
        initCartAgent();
        marketSettings = await getMarketSettings();
        await initOptimizely();
        await fetchCartPhraseIfNeeded();
        // Activate Flexi-Cart experiments on load
        if (optimizely) {
            flexiCartExperiments.forEach((experiment: string) => {
                optimizely?.activate(experiment);
            });
        }
        // Only fetch cart if bucketed in flexi-cart experiment & cart is not empty
        if ((isFlexiCartExperimentOn(optimizely) && !isCartEmpty) || isDev) {
            cachedCartResult = await getCart();
        }
    }
    /**
     *  When a user leaves certain pages we need to clear the store, since it likely contains outdated information.
     * Example pages:
     *  - the login page, where another account might now be used
     */
    const clearStorePaths = [
        `/${country}/${language}/profile`,
        `/${country}/${language}/login`,
    ];
    if (clearStorePaths.some(path => window.location.pathname.includes(path))) {
        window.addEventListener('beforeunload', () => {
            clearCartStore();
        });
    }
    publish(AGENT_LOADED, null);
}
