import type { SettingsDClass } from '../types';
import { reportError } from './sentry';
import { shoppingCartUrl } from './settings';

const defaultSettings: SettingsDClass = {
    analytics: {
        price: {
            itemPriceType: 'exclTax',
            cartPriceType: 'inclTax',
        },
    },
    price: {
        currencyCode: 'EUR',
    },
};

const getMarketSettings = async (): Promise<SettingsDClass> => {
    const baseUrl = `${shoppingCartUrl}data/settings/settings.json`;

    try {
        const response = await fetch(baseUrl);
        if (!response.ok) {
            throw new Error(`Error fetching settings: ${response.status}`);
        }
        const data = await response.json();
        // Transform the fetched data to only pick the needed fields
        const transformedData: SettingsDClass = {
            analytics: { price: data.analytics.price },
            price: {
                currencyCode: data.price.currency_code,
            },
        };
        return transformedData;
    } catch (error) {
        reportError(
            `Error fetching settings returning default settings: ${error}`
        );
        return defaultSettings;
    }
};

export default getMarketSettings;
